<template>
  <ed-table
  :key="$root.$session.versao"
     strModel="Campanha/Lista"
    :headers="arrayHeaders"
    :rows="arrayRows"
    :pagination="objPagination"
    :filter="formFiltros"
    :loading="loading"
    @register="onRegister"
    @edit="onEdit"
    @search="getCampanha"
  />
</template>

<script>
import { EdTable } from "@/components/common/form";
export default {
  name: "EdModelo",
  props: {
  },
  components: {
    EdTable,
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        objTipoModelo: null,
        arrayParametros:[]
      },
      loading: false,
      arrayHeaders: [
        {
          text: "Código",
          sortable: true,
          value: "intId",
          width:"100px"
        },
        {
          text: "Nome",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Tipo de Lista",
          sortable: true,
          value: "strTipoLista",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.getCampanha()
    },

    onRegister() {
      this.$router.push({
        name: this.$route.name+'.register'
      });
    },

    onEdit(data) {
      this.$router.push({
        name: this.$route.name+'.edit',
        params: { intId: data.intId },
      });
    },

    getCampanha() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Campanha/Lista", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objModelo) => {
              let item = {
                intId: objModelo.intId,
                strNome: objModelo.strNome,
                strTipoLista:objModelo.tipo_lista.strNome,
                _item: objModelo,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
